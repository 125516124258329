/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/

.sideBar-adjustment-category-item {
  margin-bottom: 1em;
}

.sideBar-adjustment-category-item-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.sideBar-adjustment-category-item-header .category-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.sideBar-adjustment-category-item-header-noOverridden {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.sideBar-adjustment-category-item-header .sideBar-toggle {
  /*display: inline-block;*/
  /*margin-right: 10px;*/
  width: 16px;
  height: 16px;
}

.sideBar-adjustment-category-item-actions-collapse {
  vertical-align: middle;
  height: 25px;
  display: inline-block;
  margin-left: 0.5em;
}

.sideBar-adjustment-category-item-header span svg {
  fill: #a3a8ba;
}

.sideBar-adjustment-category-item > .sideBar-adjustment-group {
  margin-left: 0.1em;
  margin-right: 0.3em;
}
