/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/

.years-projected {
  padding: 0 7px 7px 7px;
  margin: 0 7px 7px 7px;
}

.years-projected .years-projected-header {
  color: #a3a8ba;
  font-size: 14px;
}
