/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/
.selection-bar {
  padding-left: 15px;
  padding-right: 15px;
}
