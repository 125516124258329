/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/
.main-content[data-top-graph='open'] {
  margin-left: 22em; }

.main-content[data-top-graph='close'] {
  margin-left: 2em; }

.ib-img {
  width: 16px;
  height: 16px;
  color: white; }

header {
  padding: 1em; }

aside {
  color: #e8e4ee; }
