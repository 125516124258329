/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/

.adjustment-drop-down .dropdown
{
    width:100%;
    margin-bottom: 0.5em;
}

.adjustment-drop-down .dropdown button.dropdown-toggle
{
    background-color: #242833;
    color:#a3a8ba;
    width: 100%;
    text-align: left;
    border-color:#242833;
}

.adjustment-drop-down .dropdown button span.caret
{
    position: absolute;
    top:1em;
    right: 1em;
}



.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus
{
    background-color: #242833;
    color:#a3a8ba;
}


.adjustment-drop-down .dropdown .dropdown-menu
{
    background-color: #242833;
    width: 100%;
    color:#a3a8ba;
}


.adjustment-drop-down .dropdown .dropdown-menu > li > a:link
{
    color:inherit;
}
