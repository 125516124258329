/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/
.adjustment-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adjustment-slider div {
  width: 236px;
}
.adjustment-slider input {
  width: 4.2rem;
  height: 1.9rem;
  color: #a4aabb;
  text-align: center;
  background: rgb(31, 35, 48);
  border: none;
}

.adjustment-slider .rangeslider.rangeslider-horizontal .rangeslider__fill
{
  background-color: #00bbe6;
}