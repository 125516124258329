/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/
.sideBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 4;
  opacity: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}

/* custom style for apply button, bsStyle prom_apply start */
.btn-prom_apply {
  background-color: rgb(91, 197, 102);
  color: white;
  margin-left: 5px;
  border: 1px solid #ccc;
}
.btn-prom_apply:hover {
  color: white;
  border-color: #adadad;
}
/* custom style for apply button, bsStyle prom_apply end */

/* SideBarAdjustmentGroups start */
.sideBar-adjustment-groups {
  padding: 7px;
  margin: 7px;
  background: #3c4255;
}
.sideBar-adjustment-groups .sideBar-adjustment-category-header {
  display: flex;
  align-items: center;
  height: 58px;
  cursor: pointer;
}
.sideBar-adjustment-groups .sideBar-adjustment-category-header h1 {
  font-size: 16px;
  line-height: 16px;
  margin-left: 10px;
}
/* SideBarAdjustmentGroups end */

/* SideBarAdjustmentGroup start*/
.sideBar-adjustment-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.625rem;
  cursor: pointer;
}

.sideBar-adjustment-group-header span {
  max-height: 20px;
}

.sideBar-adjustment-group-header span svg {
  fill: #a3a8ba;
}

.sideBar-adjustment-group-header .adjustment-group-label {
  display: flex;
  align-items: center;
}

.sideBar-adjustment-group-header h1 {
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
}

.sideBar-adjustment-subgroup {
  padding: 8px;
  border: 0.5px solid rgba(163, 168, 186, 0.5);
}

/* SideBarAdjustmentGroup end*/

/* SideBarAdjustment start */

.sideBar-adjustment {
  margin-top: 1em;
}

.sideBar-adjustment:first-child {
  margin-top: 0;
}

.sideBar-adjustment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

/* SideBarAdjustment end */

/* AdjustmentYearRates5 start */
.adjustment-year-rates-5 tbody tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adjustment-year-rates-5 tbody tr div {
  color: #a3a8ba;
  font-size: 14px;
  font-weight: 400;
}

/* AdjustmentYearRates5 end */

/* AdjustmentYearRates10 start */
.adjustment-year-rates-10 tbody tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adjustment-year-rates-10 tbody tr div {
  color: #a3a8ba;
  font-size: 14px;
  font-weight: 400;
}

/* AdjustmentYearRates10 end */

.sideBar-toggle {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  border: 1px solid white;
  min-width: 22px;
  min-height: 22px;
  border-color: #a4aabb;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.inputDigits {
  color: #a4aabb;
  width: 4em;
  border-color: #1f2330;
  background-color: #1f2330;
}

.inputDigits.changed {
  box-shadow: 0 0 10px green;
}

.inputDigits.error {
  box-shadow: 0 0 10px red;
}

.inputDigits.locked {
  box-shadow: 0 0 10px yellow;
}

.control-label.changed {
  color: #8fed8f;
}

.control-label {
  font-size: 14px;
  white-space: nowrap;
  color: #a3a8ba;
  padding-right: 0;
  cursor: 'pointer';
}

.sideBar-header {
  height: 4.625rem;
  font-weight: 400;
  line-height: 2.625rem;
  text-align: left;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: -0.5rem;
  color: #d6dbde;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.sideBar-group-icon {
  width: 25px !important;
  height: 25px !important;
}

.sideBar-group-icon svg {
  width: 25px;
  height: 25px;
}

.sideBar-group-icon {
  fill: #a3a8ba;
}

.sideBar-menu-section-title {
  margin-right: 0.625rem;
  font-size: 16px;
}
