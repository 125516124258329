.adjustment-year-rates-30 {
  color: #a3a8ba;
  background: #1f2330;
  padding: 0.2em;
  border-radius: 5px;
}
  .adjustment-year-rates-30 table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 0.4rem;
  }
    .adjustment-year-rates-30 table tbody tr:nth-child(1) {
      font-size: 14px;
      cursor: default;
    }
      .adjustment-year-rates-30 table tbody tr td {
        padding-left: 0.2em;
        padding-right: 0.2em;
      }
      .adjustment-year-rates-30 table tbody tr td:hover {
        color: white;
      }

.modal-data-sheet {
  height: 30em;
  overflow: auto;
}
  .modal-data-sheet span table tbody tr:nth-child(1) {
    color: #999;
    background: #f5f5f5;
  }
  .modal-data-sheet span table tbody tr td:nth-child(1){
    min-width: 95px;
  }
