/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/

.scenario {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.scenario button {
  margin-left: 10px;
}