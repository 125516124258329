/********************************************************************************************
 Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
 This file is part of the Prometheus product
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
********************************************************************************************/

.scenarioSummaryContainer {
  padding: 1rem;
}

.summaryHeading {
  display: inline-block;
  margin: 1rem 0 0.5rem 0;
}

.summaryHeading:first-of-type {
  margin-top: 0;
}
