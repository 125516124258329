/* START general DownloadSelector styles */
.download-selector {
  font-size: 14px;
  user-select: none;
}
/* END general Download Selector styles */

/* START DownloadSelector heading styles */
.download-selector .heading {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 36px;
  text-align: center;
  background: white;
  padding: 1em 1em .5em 1em;
}
/* END DownloadSelector heading styles */

/* START styles for the content wrapper of the DownloadSelector page */
.download-selector-content {
  display: grid;
  grid-template-columns: repeat(2, 380px);
  grid-gap: 15px;
  margin: 2em 15px 2em 15px;
  justify-content: center;
}

.download-selector-content .panel-heading {
  white-space: nowrap;
  padding: 8px;
}

.download-selector-content .panel-body {
  padding: 0;
}
  .download-selector-content .panel-body table:nth-child(even) {
    background: #f5f5f5;
  }
/* END styles for the content wrapper of the DownloadSelector page */

/* START styles for the TopicRow component */
.download-selector-content .topic-row .topic-row-content {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
  .download-selector-content .topic-row .topic-row-content span {
    cursor: pointer;
  }
  .download-selector-content .topic-row .topic-row-content span:nth-child(1):hover > label {
    color: rgb(18, 185, 229);
  }
  .download-selector-content .topic-row .topic-row-content input {
    cursor: pointer;
    margin-right: 5px;
  }
  .download-selector-content .topic-row .topic-row-content label {
    color: #5BB372;
    cursor: pointer;
    margin: 0;
    transition: .25s;
  }
  .download-selector-content .topic-row .topic-row-content .expand-toggle .glyphicon {
    font-size: 12px;
    margin-left: 10px;
    color: rgb(91, 197, 102);
  }
  .download-selector-content .topic-row .topic-row-content .expand-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #999999;
    font-size: 12px;
    width: 100%;
  }
  .download-selector-content .topic-row .topic-row-content .rotated.glyphicon {
    transform: rotate(-180deg);
  }
  .download-selector-content .topic-row .topic-row-content .expand-toggle span {
    transition: .25s;
  }
  .download-selector-content .topic-row .topic-row-content .expand-toggle:hover > span:nth-child(1) {
    margin-right: 5px;
  }
  .download-selector-content .topic-row .topic-row-content .expand-toggle:hover > .glyphicon {
    color: rgb(18, 185, 229);
  }
/* END styles for the TopicRow component */

/* START styles for the TopicChart component */
.download-selector-content .topic-chart th {
  display: flex;
  border-bottom: 0;
}
  .download-selector-content .topic-chart th input {
    margin-right: 5px;
  }
/* END styles for the TopicChart component */

/* START styles for the ChartSeries component */
.download-selector-content .chart-series {
  display: flex;
  align-items: center;
}
  .download-selector-content .chart-series input {
    margin-right: 5px;
  }
/* END styles for the ChartSeries component */

/* START styles for the DownloadSelector button group */
.download-selector .button-group {
  position: sticky;
  bottom: 34px;
  text-align: center;
  background: white;
  padding: 1em;
  margin: 0 auto;
  z-index: 10;
}
  .download-selector .button-group button {
    width: 115px;
  }

.download-selector .button-group button:nth-child(1) {
  color: white;
  background: rgb(91, 197, 102);
}
.download-selector .button-group button:nth-child(2) {
  background: #FAFAFA;
}
/* END styles for the DownloadSelector button group */

/* START styles for specific element types */
.download-selector-content li {
  margin: 2px 0 2px 0;
  cursor: default;
}
.download-selector-content input, .download-selector-content label {
  cursor: pointer;
  margin: 0;
}
.download-selector-content table {
  margin-bottom: 0;
}
.download-selector-content thead {
  border-top: .5px solid #CCC;
}
.download-selector-content th {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
/* END styles for specific element types */

/* START media queries */
@media only screen and (max-width: 795px) {
  .download-selector-content {
    grid-template-columns: 380px;
  }
}
/* END media queries */
